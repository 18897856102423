import React from 'react'

import Layout from '../component/layout'
import Link from '../component/link'
import Image from '../component/image'
import JourneyOmnidya from '../component/journeyOmnidya'

const FAQ = () => {
  return(
    <Layout title="FAQ" captions="">
      <div className="container-xxl">
        <div className="row justify-content-center text-center">
          <div className="col-xs col-lg-6">
            <div className="header-slogan py-5">
              The right coverage for you is waiting in the Omnidya app
            </div>
            <Link to="#" className="btn btn-gradient">Get a Quote</Link>
          </div>
        </div>
      </div>
      <div className="content-bg mb-5">
        <div className="container-xxl">
          <div className="text-center mb-5">
            <div className="header-slogan pb-5">
              FAQ
            </div>
            <div className="row justify-content-center">
              <div className="col-xs col-md-8 col-lg-5">
                <p>We answered your frequently asked questions so hopefully you don't have to ask them anymore. Don't see your question? Reach out to the Omnidya team. We won't bite.</p>
              </div>
            </div>
          </div>
          {/* <!-- Full Coverage Start --> */}
          <div className="row align-items-center justify-content-center">
            <div className="col-xs col-md-6 col-lg-4 order-sm-last text-md-right mb-5 mb-md-0">
              <Image src="carFullCoverage.svg" className="img-fluid" />
            </div>
            <div className="col-xs col-md-6 col-lg-4 order-sm-first">
              <div className="sub-Title mb-3">Full Coverage</div>
              <p>
                What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industryGreat talent can be found anywhere in the world. We make it easy for you to employ
              </p>
            </div>
          </div>
          {/* <!-- FAQ Accordion  --> */}
          <div className="row justify-content-center mb-5">
            <div className="col-xs col-lg-8">
              <div id="accordionExample">
                <div>
                  <div id="headingOne">
                    <Link className="btn btn-block btn-link accordion-link text-body px-0 text-left" type="button" data-toggle="collapse" data-target="#wellDrive" aria-expanded="true" aria-controls="wellDrive">
                      <Image src="accordion-icon.svg" className="mr-3" />
                      Is my premium only based on how well I drive?
                    </Link>
                  </div>
                  <div id="wellDrive" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="py-3">
                      What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industryGreat talent can be found anywhere in the world. We make it easy for you to employ the world's best person for any job, regardless of location.
                    </div>
                  </div>
                </div>
                <div>
                  <div id="headingTwo">
                    <Link className="btn btn-block btn-link accordion-link text-body px-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#addPeople" aria-expanded="false" aria-controls="addPeople">
                      <Image src="accordion-icon.svg" className="mr-3" />
                      Can I add other people to my policy?
                    </Link>
                  </div>
                  <div id="addPeople" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="py-3">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                    </div>
                  </div>
                </div>
                <div>
                  <div id="headingThree">
                    <Link className="btn btn-block btn-link accordion-link text-body px-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#haveInsurance" aria-expanded="false" aria-controls="haveInsurance">
                      <Image src="accordion-icon.svg" className="mr-3" />
                      What if I already have insurance?
                    </Link>
                  </div>
                  <div id="haveInsurance" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div className="py-3">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                    </div>
                  </div>
                </div>
                <div>
                  <div id="headingFour">
                    <Link className="btn btn-block btn-link accordion-link text-body px-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#outofState" aria-expanded="false" aria-controls="outofState">
                      <Image src="accordion-icon.svg" className="mr-3" />
                      What if Omnidya is available in my state but I have an out-of state license?
                    </Link>
                  </div>
                  <div id="outofState" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                    <div className="py-3">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                    </div>
                  </div>
                </div>
                <div>
                  <div id="headingFive">
                    <Link className="btn btn-block btn-link accordion-link text-body px-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#removeCreditCards" aria-expanded="false" aria-controls="removeCreditCards">
                      <Image src="accordion-icon.svg" className="mr-3" />
                      How can I remove my Credit Cards?
                    </Link>
                  </div>
                  <div id="removeCreditCards" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                    <div className="py-3">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- FAQ Accordion  -->
          <!-- Full Coverage End -->
          <!-- Liability Coverage Start --> */}
          <div className="row align-items-center justify-content-center">
            <div className="col-xs col-md-6 col-lg-4 mb-5 mb-md-0">
              <Image src="carFullCoverage.svg" className="img-fluid" />
            </div>
            <div className="col-xs col-md-6 col-lg-4">
              <div className="sub-Title mb-3">Liability Coverage</div>
              <p>
                What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industryGreat talent can be found anywhere in the world. We make it easy for you to employ
              </p>
            </div>
          </div>
          {/* <!-- Liability Accordion  --> */}
          <div className="row justify-content-center mb-5">
            <div className="col col-lg-8">
              <div id="liabilityaccordionExample">
                <div>
                  <div id="liabilityheadingOne">
                    <Link className="btn btn-block btn-link accordion-link text-body px-0 text-left" type="button" data-toggle="collapse" data-target="#bodilyInjury" aria-expanded="true" aria-controls="bodilyInjury">
                      <Image src="accordion-icon.svg" className="mr-3" />
                      Bodily Injury
                    </Link>
                  </div>
                  <div id="bodilyInjury" className="collapse show" aria-labelledby="liabilityheadingOne" data-parent="#liabilityaccordionExample">
                    <div className="py-3">
                      What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industryGreat talent can be found anywhere in the world. We make it easy for you to employ the world's best person for any job, regardless of location.
                    </div>
                  </div>
                </div>
                <div>
                  <div id="liabilityheadingTwo">
                    <Link className="btn btn-block btn-link accordion-link text-body px-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#register" aria-expanded="false" aria-controls="register">
                      <Image src="accordion-icon.svg" className="mr-3" />
                      How to Register?
                    </Link>
                  </div>
                  <div id="register" className="collapse" aria-labelledby="liabilityheadingTwo" data-parent="#liabilityaccordionExample">
                    <div className="py-3">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                    </div>
                  </div>
                </div>
                <div>
                  <div id="liabilityheadingThree">
                    <Link className="btn btn-block btn-link accordion-link text-body px-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#creditCard" aria-expanded="false" aria-controls="creditCard">
                      <Image src="accordion-icon.svg" className="mr-3" />
                      How to add Credit Care?
                    </Link>
                  </div>
                  <div id="creditCard" className="collapse" aria-labelledby="liabilityheadingThree" data-parent="#liabilityaccordionExample">
                    <div className="py-3">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                    </div>
                  </div>
                </div>
                <div>
                  <div id="liabilityheadingFour">
                    <Link className="btn btn-block btn-link accordion-link text-body px-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#removeCreditCard" aria-expanded="false" aria-controls="removeCreditCard">
                      <Image src="accordion-icon.svg" className="mr-3" />
                      How can I remove my Credit Card?
                    </Link>
                  </div>
                  <div id="removeCreditCard" className="collapse" aria-labelledby="liabilityheadingFour" data-parent="#liabilityaccordionExample">
                    <div className="py-3">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Liability Accordion  -->
          <!-- Liability Coverage End --> */}

        </div>
      </div>
      <JourneyOmnidya gutter/>
    </Layout>
  )
}

export default FAQ